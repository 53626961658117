<template>
  <v-select
    :model-value="language"
    @update:model-value="async (x) => await setLocale(x)"
    hide-details
    density="compact"
    variant="outlined"
    :items="items"
    item-text="title"
    item-value="value"
  ></v-select>
</template>

<script setup lang="ts">
import { usePreferredLocale } from '@/composables/useLocale';

import { computed } from 'vue';

const { language, setLocale } = usePreferredLocale();

const items = computed(() => [
  {
    title: 'Deutsch',
    value: 'de',
    props: {
      appendIcon: language.value.startsWith('de') ? 'mdi-check' : '',
      color: language.value.startsWith('de') ? 'secondary' : '',
    },
  },
  {
    title: 'Français',
    value: 'fr',
    props: {
      appendIcon: language.value.startsWith('fr') ? 'mdi-check' : '',
      color: language.value.startsWith('fr') ? 'secondary' : '',
    },
  },
]);
</script>
