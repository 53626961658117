// Utilities
import { initializeApp, FirebaseApp } from 'firebase/app';
import { getAuth, Auth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, Firestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getStorage, FirebaseStorage, connectStorageEmulator } from 'firebase/storage';
import { getFunctions, Functions, connectFunctionsEmulator } from 'firebase/functions';
import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useLocalStorage } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import vuetify from '@/plugins/vuetify';

export const useAppStore = defineStore('app', () => {
  const firebaseApp = ref<FirebaseApp | null>(null);
  const firebaseAuth = ref<Auth | null>(null);
  const firebaseFirestore = ref<Firestore | null>(null);
  const firebaseStorage = ref<FirebaseStorage | null>(null);
  const firebaseFunctions = ref<Functions | null>(null);
  const globalLoading = ref(false);

  // const i18n = useI18n();
  // const localStorageLang = useLocalStorage('language', 'de');

  // const country = ref('CH');

  // const language = computed({
  //   get: () => {
  //     return localStorageLang.value;
  //   },
  //   set: (value: string) => {
  //     if (!i18n.availableLocales.includes(value)) return;
  //     localStorageLang.value = value;
  //     vuetify.locale.current.value = value;
  //   },
  // });

  // const currencySymbol = computed(() => {
  //   return i18n.n(0, 'currency').replace(/\d/g, '').replace('.', '').replace(',', '').trim();
  // });

  // watch(
  //   [language, country],
  //   ([newLang, newCountry]) => {
  //     i18n.locale.value = newLang + '-' + newCountry;
  //     document.documentElement.lang = newLang + '-' + newCountry;
  //   },
  //   { immediate: true },
  // );

  function init() {
    if (firebaseApp.value) return;

    const config = import.meta.env.VITE_FIREBASE_APP_CONFIG;
    firebaseApp.value = initializeApp(JSON.parse(config));
    firebaseAuth.value = getAuth(firebaseApp.value);
    firebaseFirestore.value = getFirestore(firebaseApp.value);
    firebaseStorage.value = getStorage(firebaseApp.value);
    firebaseFunctions.value = getFunctions(firebaseApp.value, 'europe-west6');

    if (import.meta.env.VITE_USE_FIREBASE_EMULATOR === 'true') {
      console.log('Using Firestore Emulator');
      connectAuthEmulator(firebaseAuth.value, 'http://localhost:9099');
      connectStorageEmulator(firebaseStorage.value, 'localhost', 9199);
      connectFirestoreEmulator(firebaseFirestore.value, 'localhost', 8081);
    }

    if (import.meta.env.VITE_USE_FIREBASE_EMULATOR === 'true' || import.meta.env.VITE_USE_FUNC_EMULATOR === 'true') {
      console.log('Using Functions Emulator');
      connectFunctionsEmulator(firebaseFunctions.value, '127.0.0.1', 5001);
    }
  }

  return {
    init,
    firebaseApp,
    firebaseAuth,
    firebaseFirestore,
    firebaseStorage,
    firebaseFunctions,
    globalLoading,
  };
});
