<template>
  <v-sonner position="bottom-center" />
  <v-app full-height>
    <AppBar />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
import { useAppStore } from './store/app';
import { useAuthStore } from './store/auth';
import AppBar from './components/AppBar.vue';
import { VSonner } from 'vuetify-sonner';
import { watch } from 'vue';
import 'vuetify-sonner/style.css';
import './styles/main.scss';
import './styles/dragAndDrop.scss';
import { usePreferredLocale } from './composables/useLocale';
import { useI18n } from 'vue-i18n';

const appStore = useAppStore();
const authStore = useAuthStore();
appStore.init();
authStore.init();

const { locale } = usePreferredLocale();
const i18n = useI18n();

watch(
  locale,
  (newLocale) => {
    i18n.locale.value = newLocale;
  },
  { immediate: true },
);
</script>
