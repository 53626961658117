import { createI18n } from 'vue-i18n';
import { de, fr } from 'vuetify/locale';
import messages from '@intlify/unplugin-vue-i18n/messages';

const v18nInstance = createI18n({
  legacy: false, // you must set `false`, to use Composition API
  availableLocales: ['de', 'fr'],
  locale: 'de', // set locale
  fallbackLocale: 'de', // set fallback locale,
  numberFormats: {
    'de-DE': {
      currency: {
        style: 'currency',
        currency: 'EUR',
      },
    },
    'de-CH': {
      currency: {
        style: 'currency',
        currency: 'CHF',
      },
    },
    'fr-CH': {
      currency: {
        style: 'currency',
        currency: 'CHF',
      },
    },
  },
  messages: {
    de: { $vuetify: de, ...messages!['de'] },
    fr: { $vuetify: fr, ...messages!['fr'] },
    'de-DE': { $vuetify: de, ...messages!['de-DE'] },
    'de-CH': { $vuetify: de, ...messages!['de-CH'] },
  },
  // Configuration options for v18n
});

console.log(v18nInstance.global.availableLocales);

export default v18nInstance;
